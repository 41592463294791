let modalButtons = document.querySelectorAll("[data-modal]");
let modals = document.querySelectorAll(".modal");

modalButtons.forEach((button) => {
    button.addEventListener("click", function () {
        let target = this.getAttribute("data-modal-target");
        showModal(target);
    });
});

function hideModal() {
    modals.forEach((modal) => {
        if (modal.style.display == "block") {
            removeBackdrop();

            modal.classList.remove("show");
            setTimeout(() => {
                modal.style.display = "none";
            }, 300);
        }
    });
}

function showModal(target) {
    document.querySelector(target).style.display = "block";
    createBackdrop();
    setTimeout(() => {
        document.querySelector(target).classList.add("show");
    }, 1);
}

function createBackdrop() {
    if (!document.querySelector(".modal__backdrop")) {
        var elemDiv = document.createElement("div");
        elemDiv.className = "modal__backdrop";
        document.body.appendChild(elemDiv);
    }
}

function removeBackdrop() {
    document.querySelector(".modal__backdrop").remove();
}

document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
        hideModal();
    }
});

document.addEventListener("click", (event) => {
    if (!event.target.closest(".modal__content, [data-modal]")) {
        hideModal();
    }
    if (event.target.matches(".modal__close")) {
        hideModal();
    }
});
